import { render, staticRenderFns } from "./index.vue?vue&type=template&id=366acaec"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderFull: require('/code/current/components/HeaderFull.vue').default,PromoContent: require('/code/current/components/PromoContent.vue').default,ClaimsList: require('/code/current/components/ClaimsList.vue').default,ContentColumns: require('/code/current/components/ContentColumns.vue').default,ReviewsWidget: require('/code/current/components/ReviewsWidget.vue').default,FooterFull: require('/code/current/components/FooterFull.vue').default})
