
import PromoContent from '../components/PromoContent.vue';
import ClaimsList from '../components/ClaimsList.vue';
import HeaderFull from '../components/HeaderFull.vue';
import FooterFull from '../components/FooterFull.vue';
import ContentColumns from '../components/ContentColumns.vue';

export default {
  components: {
    PromoContent,
    HeaderFull,
    FooterFull,
    ClaimsList,
    ContentColumns
  },
  data() {
    return {
      page: {
        content: {
          title: '',
          content: '',
          title_second: '',
          content_second: ''
        }
      },
      loading: true
    };
  },
  async fetch() {
    const tmp = await this.$content(this.$i18n.localeProperties.code.substr(0, 2) + '/contents')
        .where({'code': 'homepage'})
        .fetch();
    this.page = tmp[0];
    await this.$store.commit('seo/setTitle', this.page.seo.title);
    await this.$store.commit('seo/setDescription', this.page.seo.description);
    await this.$store.commit('seo/setKeywords', this.page.seo.keywords);
    await this.$store.commit('seo/setImage', this.page.image + '?format=webp');
    await this.$store.commit('seo/setCanonical', this.localeRoute({
      name: 'index'
    }).fullPath);
    this.loading = false;
  }
};
