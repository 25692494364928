
export default {
  data() {
    return {
      summary: {
        count: 0,
        average: 0
      },
      reviews: []
    }
  },
  async fetch() {
    let summary = await this.$content({path: '/feedbacks'}).fetch();
    this.summary = summary[0];

    const reviews = await this.$content('feedbacks').where({rating: 5, review: {$ne: null}}).sortBy('created_at', 'desc').limit(4).fetch();
    this.reviews = reviews;
  },
}
